import router from './plugins/router'

export type importType = {
    [key: string]: Record<string, any>
}

const imports: importType = {
    views: import.meta.glob('@modules/*/views/*/*.vue'),
    //staticViews: import.meta.glob('@modules/*/views/*/*.vue', {eager: true,}),
    //components: import.meta.glob('@modules/*/components/**/*.vue'),
    setups: import.meta.glob('@modules/*/setup.ts', { eager: true }),
    routers: import.meta.glob('@modules/*/router.ts', { eager: true }),
}

export type moduleType = {
    [key: string]: importType
}

const moduleData: moduleType = {
    views: {},
    components: {},
    setups: {},
    routers: {},
    staticViews: {},
}

for (const groupName in imports) {
    const group = imports[groupName]
    for (const path in group) {
        const splitPath = path.split('/')
        const module = splitPath[3]

        if (!moduleData[groupName][module]) {
            moduleData[groupName][module] = []
        }
        let x = 0,
            fileName: string,
            bool: boolean
        do {
            x -= 1
            fileName = splitPath.at(x)?.split('.')[0] || ''
            bool = ['index', 'script', 'style'].some((str) => {
                return fileName.toLowerCase() === str.toLowerCase()
            })
        } while (bool)
        moduleData[groupName][module].push({
            path,
            name: fileName,
            import: group[path].default || group[path],
        })
    }
}
//export const staticViews = moduleData.staticViews
export const views = moduleData.views
//export const components = moduleData.components
export const setups = Object.values(moduleData.setups).map((obj) => obj[0])
export const routers = Object.values(moduleData.routers).map((obj) => obj[0])
