import type { Directive } from 'vue'
// @ts-ignore
import safeHtml from 'safe-html'

export default {
    mounted(el: Element, binding: any) {
        const { value } = binding
        const options = {
            allowedTags: [
                // Headers
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
                'h6',
                // Styles
                'b',
                'i',
                'em',
                'strong',
                's',
                'sup',
                'sub',
                // Paragraph / Line-breaks
                'p',
                'br',
                'hr',
                // Anhor
                'a',
                // Image
                'img',
                // List
                'ol',
                'ul',
                'li',
                // Blockquote
                'blockquote',
                // Table
                'table',
                'tbody',
                'caption',
                'tr',
                'th',
                'td',
                // Misc
                'div',
                'span',
            ],
            allowedAttributes: {
                id: {
                    allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'],
                },
                style: {
                    allowedTags: ['p', 'img', 'div'],
                },
                class: {
                    allTags: true,
                },
                href: {
                    allowedTags: ['a'],
                },
                target: {
                    allowedTags: ['a'],
                },
                src: {
                    allowedTags: ['img'],
                },
                width: {
                    allowedTags: ['img'],
                },
                height: {
                    allowedTags: ['img'],
                },
            },
            allowedClasses: {
                '*': ['*'],
            },
        }
        el.innerHTML = safeHtml(value, options)
    },
} as Directive<Element, any, string, string>
