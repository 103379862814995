import { layout, moduleRoute } from '@src/helper/routes'
import type { Router } from 'vue-router'

export default (router: Router) => {
    router.addRoute(
        layout(
            'Login',
            'StaticForms',
            [
                moduleRoute(
                    'GI',
                    'StaticEneagrama',
                    '/gi/candidateForm/:token'
                ),
                moduleRoute('GI', 'JobPositionForm', '/gi/apply/job/:id'),
                moduleRoute('GI', 'JobPositionApplied', '/gi/applied'),
            ],
            '/gi'
        )
    )
}
