<template>
    <q-linear-progress
        :indeterminate="appStore.lazyLoadingRoute"
        color="primary"
        :style="
            appStore.lazyLoadingRoute
                ? 'position:absolute; top: 0; z-index: 10000;'
                : 'display: none;'
        "
    />
    <router-view />
    <cookie-law></cookie-law>
</template>

<script lang="ts" setup>
import CookieLaw from '@modules/Core/components/app/CookieLaw.vue'
import useLayoutStore from '../stores/layout'
import { useQuasar } from 'quasar'
import { definePalette } from '../helper/theme'
import useUserStore from '../stores/user'
import useAppStore from '@src/stores/app'
import i18nLib from '@src/plugins/i18n'
import * as icons from 'simple-icons'
import { useRouter } from 'vue-router'

const layoutStore = useLayoutStore()
const appStore = useAppStore()
const userStore = useUserStore()
const quasar = useQuasar()
const router = useRouter()

quasar.iconMapFn = function (name) {
    if (name.startsWith('si')) {
        //@ts-ignore
        if (icons[name]) {
            return {
                //@ts-ignore
                icon: icons[name].path,
            }
        }
    }
}
onMounted(() => {
    i18nLib.global.locale.value = layoutStore.locale
    layoutStore.changeLocale(layoutStore.locale, quasar)
    definePalette(layoutStore.theme.light)
})

router.beforeEach((to, from) => {
    appStore.lazyLoadingRoute = true
    return true
})

router.afterEach(() => {
    appStore.lazyLoadingRoute = false
})

if (userStore.isLoggedIn) {
    // On refresh page, reload necessary data
    userStore.updateRoutes()
    userStore.updateModules()
    userStore.updatePermissions()
}
</script>
