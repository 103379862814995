import setupQuasar from '@src/plugins/quasar'
import setupApexCharts from '@src/plugins/apexCharts'
import pinia from '@src/plugins/pinia'
import i18n from '@src/plugins/i18n'
import router from '@src/plugins/router'
import type { App } from 'vue'

// Run setup function for every module
import { setups } from '@src/modules'
import sanitizeHtml from '@src/libs/sanitizeHtml'

export default function (app: App) {
    // Manter ordem abaixo

    app.use(pinia).use(i18n)

    window.updateRouter()

    app.use(router)

    router.push({ path: window.location.pathname, query: { root: 'true' } })

    setups.forEach((setup) => {
        setup.import({ pinia, router, i18n, app })
    })

    setupQuasar(app)
    setupApexCharts(app)

    app.directive('safeHtml', sanitizeHtml)
}
