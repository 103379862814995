import { layout, moduleRoute } from '@src/helper/routes'
import type { Router } from 'vue-router'

export default (router: Router) => {
    router.addRoute(
        layout(
            'Login',
            'Invitation',
            [
                moduleRoute(
                    'Core',
                    'EmployeeInvitation',
                    '/core/web/employeeInvitation/:token'
                ),
                moduleRoute('Core', 'AutoLogin', '/core/autologin/user/:token'),
            ],
            '/core'
        )
    )
}
